var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "batchState",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 0, "active-value": 1 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.batchState,
                          callback: function($$v) {
                            _vm.$set(scope.row, "batchState", $$v)
                          },
                          expression: "scope.row.batchState"
                        }
                      })
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.formTable,
                callback: function($$v) {
                  _vm.formTable = $$v
                },
                expression: "formTable"
              }
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("MATERIAL NAME"),
                        size: "small"
                      },
                      model: {
                        value: _vm.productId,
                        callback: function($$v) {
                          _vm.productId = $$v
                        },
                        expression: "productId"
                      }
                    },
                    _vm._l(_vm.productList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: { placeholder: _vm.$t("BATCH2"), size: "small" },
                    model: {
                      value: _vm.batchCode,
                      callback: function($$v) {
                        _vm.batchCode = $$v
                      },
                      expression: "batchCode"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("STATUS"),
                        size: "small"
                      },
                      model: {
                        value: _vm.batchState,
                        callback: function($$v) {
                          _vm.batchState = $$v
                        },
                        expression: "batchState"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("NOT EFFECTIVE"), value: "0" }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("EFFECTIVE"), value: "1" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }