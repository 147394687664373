import request from '@/router/axios';
import {businessUrl} from '@/config/env'
export const list = (pageNo,pageSize,productId,batchState,batchCode) => {
    return request({
        url : businessUrl + "productBatch/selectPage",
        method: "post",
        data: {
            pageNo,pageSize,batchCode,productId,batchState
        }
    })
}
export const add = (id,params) => {
    return request({
        url: businessUrl + "productBatch/insert",
        method: "post",
        data: {
            categoryId: id,
            ...params
        }
    })
}
export const edit = (id,params) => {
    return request({
        url: businessUrl + "productBatch/update",
        method: "put",
        data: {
            id,
            ...params
        }
    })
}
export const remove = (id) => {
    return request({
        url: businessUrl + "productBatch/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const treeList = () => {
    return request({
        url: businessUrl + "dictCategory/selectAll"
    })
}